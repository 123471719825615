// utils.ts

/**
 * Função para formatar o primeiro nome
 * @param {string} fullName - Nome completo
 * @returns {string} - Primeiro nome
 */
export function getFirstName(fullName: string): string {
  const names = fullName.split(" ");
  return names[0];
}

/**
 * Função para formatar o sobrenome
 * @param {string} fullName - Nome completo
 * @returns {string} - Sobrenome
 */
export function getLastName(fullName: string): string {
  const names = fullName.split(" ");
  return names[names.length - 1];
}

/**
 * Função para retornar o nome completo
 * @param {string} firstName - Primeiro nome
 * @param {string} lastName - Sobrenome
 * @returns {string} - Nome completo
 */
export function getFullName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`;
}

/**
 * Função para converter string para maiúsculas
 * @param {string} str - String de entrada
 * @returns {string} - String em maiúsculas
 */
export function toUpperCase(str: string): string {
  return str.toUpperCase();
}

/**
 * Função para converter string para minúsculas
 * @param {string} str - String de entrada
 * @returns {string} - String em minúsculas
 */
export function toLowerCase(str: string): string {
  return str.toLowerCase();
}

/**
 * Função para converter a primeira letra de cada palavra para maiúscula
 * @param {string} str - String de entrada
 * @returns {string} - String com a primeira letra de cada palavra em maiúscula
 */
export function toUpperCaseFirstLetter(str: string): string {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

/**
 * Função para formatar números
 * @param {number} num - Número de entrada
 * @param {string} locale - Localização para formatação
 * @returns {string} - Número formatado
 */
export function formatNumber(num: number, locale: string = "en-US"): string {
  return new Intl.NumberFormat(locale).format(num);
}

/**
 * Função para validar e formatar números de telefone
 * @param {string} phone - Número de telefone de entrada
 * @returns {string} - Número de telefone formatado
 */
export function formatPhoneNumber(phone: string): string {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
}

/**
 * Função para gerar um identificador único (UUID)
 * @returns {string} - UUID gerado
 */
export function generateUUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Função para checar se um email é válido
 * @param {string} email - Email de entrada
 * @returns {boolean} - Verdadeiro se o email for válido, falso caso contrário
 */
export function isValidEmail(email: string): boolean {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}
